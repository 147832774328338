//variablen muessen pro instanz angepasst werden
module.exports = {
    mantelUrl : 'https://mantel-api.mobile.documedias.systems/v2/conferences/',
    baseUrl: 'https://mantel-api.mobile.documedias.systems/v2/checkall/',
    baseUrlDownload: 'https://mantel-api.mobile.documedias.systems/v2/downloadall/',
    baseUrlCDN: "https://documedias-188ab.kxcdn.com/",
    cdnInitStamp: "2021-07-25T13:12:33.808Z",
    conference: '1385',
    short_name: 'dbt2023',
    full_name: 'BiblioCon 2023',
    api_version: 2, //change this to api version 2 for new conferences!!!
    conf_date : '23 – 26 Mai',
    api_url : 'https://coredev.api.documedias.systems/api/',
    appointment_api_url : 'https://dev.appointment.documedias.systems/api/',
    auth_url : 'https://auth.documedias.com/',
    //web 
    auth_key : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBsaWNhdGlvbklkIjoxODQ1LCJjb25ncmVzc0lkIjoxMzg1LCJkYXRlIjoiMjAyMy0wNC0xM1QwODo0OTozNC4yNzdaIiwiaWF0IjoxNjgxMzc1Nzc0fQ.E-2Z8RURgxerQXpAmGnnfIbRgG1390jUULhnFQH51Xg',
    //mobile 
    //auth_key : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBsaWNhdGlvbklkIjoxODM0LCJjb25ncmVzc0lkIjoxMzg1LCJkYXRlIjoiMjAyMy0wMy0yMlQxMzozOToyMC4yNjhaIiwiaWF0IjoxNjc5NDkyMzYwfQ.NZStzJKxILoe4yp6yut_IASuhZ2G6o5NuRKE0KieGQI',
    auth_dev_key : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBsaWNhdGlvbklkIjoxODM0LCJjb25ncmVzc0lkIjoxMzg1LCJkYXRlIjoiMjAyMy0wMy0yMlQxMzozOToyMC4yNjhaIiwiaWF0IjoxNjc5NDkyMzYwfQ.NZStzJKxILoe4yp6yut_IASuhZ2G6o5NuRKE0KieGQI',
    vue_app_access_key : '',
    chat_url: 'https://dbt2023.chat.documedias.systems/api/',
    is_app: false, //unbedingt anpassen bevor App deploy
    favorite_api_url: 'https://dbt2023.planner.documedias.systems/api/program/favorites',
    is_multi_conf_app: false //set true for society or Conf2Go

}
